<template>
  <main class="apps-page page container-padding">
    <div class="apps-page-banner__item">
      <div class="ratio-container">
        <div class="apps-page-banner__content apps-page-banner__first">
          <div class="apps-page-banner__first-wrapper">
            <h2 class="apps-page-banner__title">Всегда с тобой</h2>
            <span class="apps-page-banner__subtitle">
              Скачай приложение Зелёного Яблока и покупай любимые продукты с бесплатной доставкой на дом
            </span>
            <div class="apps-page-banner__apps">
              <a v-for="(link, i) in links" :key="i" :href="link.link" target="_blank">
                <img :src="link.src" :alt="link.alt" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "AppsPage",
  data() {
    return {
      links: [
        {
          src: "/static/svg/app-store.svg",
          alt: "app-store icon",
          link: "https://apps.apple.com/ru/app/green-apple/id1659461623",
        },
        {
          src: "/static/svg/google-play.svg",
          alt: "google-play icon",
          link: "https://play.google.com/store/apps/details?id=com.greenApple.appClient",
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.apps-page {
  //display grid
  //grid-template-columns repeat(2, 1fr)
  //grid-gap 24px
  display flex
  justify-content center
  max-width 50%
  +below(840px) {
    //grid-template-columns 1fr
    max-width 100%
  }

  +below(628px) {
    min-height 90vh
  }

  +below(480px) {
    grid-gap 15px
  }

  &-banner {

    &__item {
      ratio(648, 600)

      +below(628px) {
        height 100%
      }
    }

    &__first {
      background-image url("/static/images/download-bg.png")
      background-size cover
      background-position: center;
      justify-content center
    }

    &__first-wrapper {
      color var(--white)
      align-items center
      text-align center
      max-width 448px
      display flex
      flex-direction column
      gap 16px

      +below(628px) {
        min-height 90vh
      }

      +below(480px) {
        gap: 10px
      }
    }

    &__content {
      border-radius 32px
      padding 64px
      display flex
      +below(1100px) {
        padding 42px
      }
      +below(990px) {
        padding 32px
      }
      +below(420px) {
        padding 24px
      }
    }

    &__title {
      font-size 2em
      font-weight 700
      line-height 44px
      margin-bottom 0
      text-transform none
      +below(990px) {
        font-size 1.5em
        line-height 1.5
      }
      +below(480px) {
        font-size 1.125em
      }
    }

    &__subtitle {
      font-size 1em
      font-weight 500
      line-height 24px
      +below(480px) {
        font-size 0.875em
        line-height 1.5
      }
    }

    &__apps {
      display flex
      gap 32px
    }

    &__btn {
      height 46px
      font-size 0.875em
      font-weight 400
      line-height 20px
      max-width 218px
      gap 4px
      padding 12px 24px
    }
  }
}
</style>
